import { createSlice } from "@reduxjs/toolkit";
import { setManualMatches } from "../tournament/thunks";

interface NotificationState {
  count: number,
  notification: {
    id: number,
    severity: "error" | "warning" | "info" | "success"
    message: string
  }[]
}


const initialState: NotificationState = {
  count: 0,
  notification: []
};

export const notificationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    deleteNotification: (state,) => {
      state.count = 0;
      state.notification = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(setManualMatches.rejected, (state, action) => {
      state.count = state.count + 1;
      state.notification = [...state.notification, { id: state.count + 1, severity: 'error', message: action.error.message ? action.error.message : "Error saved manual select" }]
    })
    builder.addCase(setManualMatches.fulfilled, (state) => {
      state.count = state.count + 1;
      state.notification = [...state.notification, { id: state.count + 1, severity: 'success', message: "Save grid" }]
    })
  }
});

export const { deleteNotification } = notificationSlice.actions;

export default notificationSlice.reducer;