import { apiUrlV1 } from "./config/config";

export const encodeGetParams = (p: Record<string, string | number | boolean>): string => {
  const queryParams = Object
    .entries(p)
    .map(kv => kv.map(encodeURIComponent).join("="))
    .join("&");
  return queryParams ? ('?' + queryParams) : '';
}

export const getToken = () => {
  return localStorage.getItem('token');
}

export const setToken = (token: string) => {
  localStorage.setItem('token', token);

  if (!token) {
    localStorage.removeItem('token');
  }
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh');
}

export const setRefreshToken = (refreshToken: string) => {
  localStorage.setItem('refresh', refreshToken);
}

const trailingUrlSlash = (s: string): string => {
  const endsWithSlash = s[s.length - 1] === '/';
  const hasQueries = s.indexOf("?") !== -1;
  return s + (!(endsWithSlash || hasQueries) ? '/' : '');
};

const urlMiddleware = (url: string): string => {
  return trailingUrlSlash(url);
}

const parseJwt = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const mergeToken = (data: RequestInit) => {
  const token = getToken();
  const nextData = { ...data };

  nextData.headers = new Headers({ ...data.headers });

  if (token) {
    nextData.headers.set('Authorization', `Token ${token}`);
  }

  // todo: find a more convenient way to fix that
  nextData.headers.set('Content-Type', `application/json`);

  if (nextData.body instanceof FormData) {
    nextData.headers.delete('Content-Type');
  }

  return nextData;
}

const isTokenExpired = (token: string) => {
  const data = parseJwt(token);
  const expTimestamp = data.exp as number;
  const nowTimestamp = new Date().getTime() / 1000
  return (expTimestamp - nowTimestamp) <= 60;
};

const freshFetch = (url: string, data: RequestInit) => {
  const token = getToken();
  const awaitTokenPromise = (token && isTokenExpired(token))
    ? new Promise<void>((resolve, reject) => {
      fetch(`${apiUrlV1}/profiles/auth-by-phone/refresh/`, {
        method: 'POST',
        headers: {
          'Authorization': `Token ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh: getRefreshToken(),
        })
      })
        .then(res => res.json())
        .then(jsonBody => {
          setToken(jsonBody.access);
          resolve();
        });
    }) : Promise.resolve();

  return awaitTokenPromise.then(() => {
    return fetch(url, mergeToken(data));
  });
};

export const agent = {
  get: (url: string) => {
    return freshFetch(`${urlMiddleware(url)}`, {
      method: "GET",
    });
  },
  post: (url: string, data: RequestInit) => {
    return freshFetch(`${urlMiddleware(url)}`, {
      method: "POST",
      ...data,
    });
  },
  put: (url: string, data: RequestInit) => {
    return freshFetch(`${urlMiddleware(url)}`, {
      method: "PUT",
      ...data,
    });
  },
  delete: (url: string, data?: RequestInit) => {
    return freshFetch(`${urlMiddleware(url)}`, {
      method: "DELETE",
      ...data,
    });
  },
  patch: (url: string, data?: RequestInit) => {
    return freshFetch(`${urlMiddleware(url)}`, {
      method: "PATCH",
      ...data,
    });
  }
}
