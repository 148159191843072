import { createTheme, makeStyles } from "@material-ui/core";
import { publicUrl } from "../config/config";

export const useGlobalStyles = makeStyles({
  "@global": {
    "*": {
      outline: 'none',
      margin: '0px',
      padding: '0px',
      boxSizing: "border-box"
    },
    '@font-face': [
      {
        fontFamily: 'Inter',
        fontWeight: 500,
        src: publicUrl + '/fonts/Inter-Medium.otf'
      },
      {
        fontFamily: 'FRIZON',
        src: publicUrl + '/fonts/Frizon.ttf'
      },
      {
        fontFamily: 'Inter',
        fontWeight: 400,
        src: publicUrl + '/fonts/Inter-Regular.otf'
      },
      {
        fontFamily: 'Inter',
        fontWeight: 600,
        src: publicUrl + '/fonts/Inter-SemiBold.otf'
      },
    ],
    html: {
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    body: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      overscrollBehavior: "contain",
      backgroundColor: '#071226',
      color: '#fff',
    },
    "#root, #clients-root": {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      width: "100vw",
      overflowY: 'auto',
    },
    main: {
      position: "relative",
      flex: 1
    },
    "a, a:hover, a:focus, a:active": {
      textDecoration: 'none',
      color: 'inherit',
    },
    button: {
      border: 'none',
      background: 'none',
      cursor: 'pointer'
    },
    ul: {
      listStyle: 'none',
    }
  }
});

export const wispTheme = createTheme({
  palette: {
    background: {
      default: '#EBF3F7',
    },
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#4191F7',
    },
    text: {
      primary: '#ffffff',
      secondary: '#4191F7',
    },
    error: {
      main: '#D24E4E'
    }
  },
  typography: {
    body1: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
    },
    body2: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: 16,
      lineHeight: '26px',
    },
    subtitle1: {
      fontFamily: "'Inter', sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: ' 17px',
      color: 'rgba(255, 255, 255, 0.32)',
    },
    h3: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '30px',
    },
    h4: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '29px',
    },
    h5: {
      fontFamily: "'Inter', sans-serif",
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '18px',
    },
    h6: {
      fontFamily: "'FRIZON',sans-serif",
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: '47px',
      background: ' linear-gradient(180deg, #4191F7 0%, #0F78FF 100%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      backgroundClip: 'text',
      textFillColor: 'transparent',
    }
  },
  overrides: {
    MuiPaper: {
      root: {
        color: '#fff',
        backgroundColor: "#0F172A"
      }
    },
    MuiButton: {
      root: {
        fontFamily: "'Inter', sans-serif",
        textTransform: 'initial',
        fontStyle: 'normal',
        lineHeight: '16px',
        fontSize: '14px',
        fontWeight: 600,
        padding: 12,
      },
      contained: {
        background: 'linear-gradient(180deg, #4191F7 0%, #0F78FF 100%)',
        borderRadius: '4000px',
        fontWeight: 600,
        padding: 12,

      },
      containedPrimary: {
        color: '#fff',
      },
      outlined: {
        padding: 12,
        border: '1px solid #4191F7',
        borderRadius: '4000px',

        background: ' linear-gradient(180deg, #4191F7 0%, #0F78FF 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }
    },
    MuiInputLabel: {
      root: {
        fontFamily: "'Inter', sans-serif",
        color: 'rgba(255, 255, 255, 0.32)',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: ' 16px',

        marginBottom: 4,
      },
      formControl: {
        position: "relative",
        transform: 'scale(1)',
      },
      shrink: {
        transform: 'scale(1)',
      }
    },
    MuiFormControl: {
      root: {
        '& label + .MuiInput-formControl': {
          marginTop: 4,
        },
        '& .Mui-error': {
          borderColor: '#D24E4E',
        }
      },
    },
    MuiInput: {
      underline: {
        background: 'rgba(255, 255, 255, 0.08)',
        borderRadius: '8px',
        padding: '8px 0px 8px 12px',
        border: '.5px solid #4191F752',

        fontFamily: "'Inter', sans-serif",
        color: '#fff',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: ' 16px',
        '&::before': {
          display: 'none'
        },
        '&::after': {
          display: 'none'
        },
        '& button[aria-label="toggle password visibility"] svg path': {
          fill: 'rgba(255,255,255,.33)',
        },
        '& button[aria-label="toggle password visibility"] svg': {
          height: '16px',
        },
        '& button[aria-label="toggle password visibility"].MuiIconButton-root:hover': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiInputBase: {
      input: {
        padding: 0,
      }
    },
    MuiFormControlLabel: {
      label: {
        fontFamily: "'Inter', sans-serif",
        color: '#fff',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: ' 16px',
      }
    },
    MuiTabs: {
      root: {
        background: ' rgba(233, 237, 245, 0.08)',
        borderRadius: '6px',
        padding: '3px',
        minHeight: 28,
        '& .Mui-selected .MuiTab-wrapper': {
          color: 'rgba(255, 255, 255, 1)',
        }
      },
      indicator: {
        height: '100%',
        borderRadius: '4px',
        background: 'linear-gradient(180deg, #4191F7 0%, #0F78FF 100%)',
        boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.25)',
      }
    },
    MuiTab: {
      root: {
        minHeight: 28,
      },
      wrapper: {
        position: 'relative',
        zIndex: 4,
        color: 'rgba(255, 255, 255, 0.56)',
        fontFamily: "'Inter', sans-serif",
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '20px',
        textTransform: 'initial'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: '#2F80ED',
        boxShadow: '0px 0px 0px 0.5px rgba(0, 0, 0, 0.08), 0px 2px 6px rgba(0, 0, 0, 0.25)',
      },
      arrow: {
        color: '#2F80ED'
      }
    }
  }
})
