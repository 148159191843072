import UserProfile, { PreRegPartner } from "../../entities/UserProfile";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { loadUser, loginParther, registretionPartner, verifyEmail } from "./thunks";

interface AuthState {
  token: string | null;
  profile: UserProfile | null;
  loading: boolean;
  preRegProfile: PreRegPartner | undefined,
  register: boolean,
  verifyEmail: boolean
}

const localStorageToken: string | null = localStorage.getItem('token') || null;

const initialState: AuthState = {
  token: localStorageToken,
  profile: null,
  loading: !!localStorageToken,
  preRegProfile: undefined,
  register: false,
  verifyEmail: false
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPreRegProfile: (state, action: PayloadAction<PreRegPartner>) => {
      state.preRegProfile = action.payload
    },
    logOut: (state) => {
      state.profile = null
      state.token = null
      localStorage.removeItem("token")
      localStorage.removeItem("refresh")
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadUser.fulfilled, (state, action) => {
      state.profile = action.payload
    })

    builder.addCase(verifyEmail.fulfilled, state => {
      state.verifyEmail = true
    })
    builder.addCase(registretionPartner.fulfilled, state => {
      state.register = true
    })
    builder.addCase(registretionPartner.rejected, state => {
    })
    builder.addCase(loginParther.fulfilled, (state, action) => {
      if (action.payload && action.payload.tokens) {
        localStorage.token = action.payload.tokens.access
        localStorage.refresh = action.payload.tokens.refresh
        state.token = action.payload.tokens.access
      }
    })
  }
});

export const { setPreRegProfile, logOut } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;
export const selectUserProfile = (state: RootState) => state.auth.profile;

export default authSlice.reducer;