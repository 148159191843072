import { makeStyles } from "@material-ui/core"
import bgEffect from '../image/effects.png'
import clsx from "clsx"
import { ReactNode } from "react"

const useStyle = makeStyles({
  wispPage: {
    width: '100%',
    margin: '38px 0px',
    overflowX: 'hidden',
    overflowY: 'auto'
  },
  wispMainContainer: {
    display: 'flex',
    width: '100%',
    paddingLeft: 219,
    position: 'relative',
    zIndex: 1
  }
})

const useAuthGlobalStyle = makeStyles({
  "@global": {
    '#root': {
      '&::after': {
        content: "' '",
        position: 'fixed',
        zIndex: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: `url(${bgEffect})`,
        opacity: 0.16,
      }
    },
    'footer, header, main': {
      position: 'relative',
      zIndex: 2,
    }
  }
})

interface PageProps {
  children?: ReactNode,
  className?: string
}

export const Page = (props: PageProps) => {
  const { children, className } = props;
  const cx = useStyle();
  return (
    <section className={clsx(cx.wispPage, className)}>
      {children}
    </section>
  )
}

export const MainContainer = (props: PageProps) => {
  const cx = useStyle();
  useAuthGlobalStyle()
  return (
    <main className={clsx(cx.wispMainContainer, props.className)}>
      {props.children}
    </main>
  )
}