import { Button, Typography } from "@material-ui/core"
import React from "react";
import { useTranslation } from "react-i18next"
import { PersonelStep } from "./PersonelStep";
import { OrganizationStep } from "./OrganizationStep";
import { DoneRegistration, SendEmailCode } from "./SendEmaiCode";
import { RootState, useAppSelector } from "../../store";

export type TypeOrganization = "club" | "organization"

export interface PersonelInfoProps {
  title: string,
  email: string,
  phone: string
}

export const RegisterForm = ({ onBack }: { onBack: () => void }) => {
  const { t } = useTranslation();
  const [step, setStep] = React.useState<'organization' | 'personel' | 'send' | 'done'>('personel');
  const [typeOrg, setTypeOrg] = React.useState<TypeOrganization>('club');

  const [regIngo, setRegInfo] = React.useState<PersonelInfoProps>();
  const [sypexGeo, setSypexGeo] = React.useState<any>();

  const { register } = useAppSelector((state: RootState) => state.auth)

  const onChangeTypeOrg = (type: TypeOrganization) => {
    setTypeOrg(type);
  }
  const savePersonelInfo = (personel: PersonelInfoProps) => {
    setRegInfo({ ...personel });
    setStep('organization');
  }
  const onSendCode = () => {
    setStep('send');
  }
  const onStepBack = () => {
    if (step === 'organization') {
      setStep('personel');
    }
    if (step === 'send') {
      setStep('organization')
    }
  }
  const sypexgeoFetch = async () => {
    try {
      const req = await fetch('https://api.sypexgeo.net/');
      if (req.ok) {
        const data = await req.json();
        setSypexGeo(data);
      }
    } catch (error) {

    }
  }
  React.useEffect(() => {
    sypexgeoFetch();
  }, [])

  React.useEffect(() => {
    if (register) {
      setStep('done')
    }
  }, [register])


  return (
    <div >
      {step !== 'done' &&
        <Typography
          variant='h6'
          component={'h2'}
          style={{ textAlign: 'center' }}
        >
          {t('new-partner')}
        </Typography>
      }
      {
        step === 'personel' &&
        <PersonelStep
          onSave={savePersonelInfo}
          onBack={onBack}
          personel={regIngo}
          typeOrg={typeOrg}
          onChangeTypeOrg={onChangeTypeOrg} />
      }
      {
        step === 'organization' && regIngo &&
        <OrganizationStep
          onBack={onStepBack}
          typeOrg={typeOrg}
          personel={regIngo}
          sypexGeo={sypexGeo}
          onSendCode={onSendCode} />
      } {
        step === 'send' &&
        <SendEmailCode
          onBack={onStepBack}

        />
      }
      {
        step === 'done' &&
        <DoneRegistration />
      }
    </div>
  )
}