import React from 'react'
import { TextField, makeValidate } from 'mui-rff'

import { useTranslation } from 'react-i18next'
import { Field, Form } from 'react-final-form'
import { PersonelInfoProps, TypeOrganization } from './RegisterForm'
import { Button, Tab, Tabs, makeStyles } from '@material-ui/core'
import { WISPPhoneInput } from './WISPPhoneInput'
import { Schema } from 'yup'
import * as Yup from 'yup'
import { Value, isValidPhoneNumber } from 'react-phone-number-input'

interface PersonelStepProps {
  typeOrg: TypeOrganization,
  personel?: PersonelInfoProps,
  onChangeTypeOrg: (type: TypeOrganization) => void,
  onSave: (personel: PersonelInfoProps) => void,
  onBack: () => void
}

const useStyle = makeStyles({
  phoneInput: {

  }
})

export const PersonelStep = ({ onSave, onBack, typeOrg, personel, onChangeTypeOrg }: PersonelStepProps) => {
  const { t } = useTranslation();
  const cx = useStyle();

  const [errorPhone, setErrorPhone] = React.useState<boolean>(false)

  const [initialValues, setInitialValues] = React.useState<PersonelInfoProps>({
    title: "",
    email: "",
    phone: ""
  })

  const schema: Schema = Yup.object().shape({
    email: Yup.string().email(`${t("login-email-error")}`).required(`${t('required')}`),
    title: Yup.string().required(`${t('required')}`),
  });

  const validate = makeValidate(schema);

  const onChange = (event: any) => {
    setInitialValues({ ...initialValues, [event.target.name]: event.target.value })
  }
  const onChangePhone = (event: Value) => {

    setInitialValues({ ...initialValues, phone: event })
  }

  const onSubmit = (peronel: PersonelInfoProps) => {
    if (peronel.phone && !isValidPhoneNumber(peronel.phone)) {
      setErrorPhone(true)
    } else {
      setErrorPhone(false)
      onSave(peronel);
    }
  }

  const onChangeTabs = (event: React.ChangeEvent<{}>, newValue: TypeOrganization) => {
    onChangeTypeOrg(newValue);
  }
  React.useEffect(() => {
    if (personel && personel.email.length >= 0) {
      setInitialValues(personel);
    }
  }, [])
  return (
    <>
      <Tabs
        value={typeOrg}
        onChange={onChangeTabs}>
        <Tab label={t("club")} value={'club'} />
        <Tab label={t("organization")} value={'organization'} />
      </Tabs>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div >
                <TextField
                  name='title'
                  label={typeOrg === 'club' ? t('club-name') : t('organization-name')}
                  inputProps={{
                    onChange
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  style={{
                    marginTop: 12
                  }} />
              </div>
              <div >
                <TextField
                  name='email'
                  type='email'
                  label={t('login-email')}
                  inputProps={{
                    onChange
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                  style={{
                    marginTop: 12
                  }} />
              </div>
              <div>
                <WISPPhoneInput
                  label={t('phone')}
                  isError={errorPhone}
                  inputPhone={{
                    value: initialValues.phone,
                    onChangePhone: onChangePhone
                  }}
                />
              </div>
              <div style={{
                marginTop: 24
              }}>
                <Button
                  // type='button'
                  // onClick={() => { console.log(values) }}
                  type='submit'
                  variant="contained"
                  color='primary'
                  fullWidth
                >
                  {t("next")}
                </Button>
              </div>
            </form>);
        }}
      />
      <Button
        style={{ marginTop: 12 }}
        variant='outlined'
        onClick={onBack}
        fullWidth>
        {t("back")}
      </Button>
    </>
  )
}

