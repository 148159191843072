import React from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../store'
import { Link, makeStyles, Button, TextField, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { verifyEmail } from '../../store/auth/thunks'
import { } from 'react-router-dom'


const useStyle = makeStyles({
  title: {
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '45px',
    background: ' linear-gradient(180deg, #4191F7 0%, #0F78FF 100%)',
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    textAlign: 'center',
    width: '444px'
  },
  text: {
    margin: '23px 0px',
    textAlign: 'center',
    width: '444px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: ' 19px',
  },
  link: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '18px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }
})

export const DoneRegistration = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Typography variant='h5' className={useStyle().title} >
        {t("thy-title")}
      </Typography>
      <Typography variant='subtitle1' component={'p'} className={useStyle().text}>
        {t("thy-text")}
      </Typography>
      <Link variant='button' href="/" className={useStyle().link} color='secondary'>
        go to wisp
      </Link>
    </div>
  )
}

export const SendEmailCode = ({ onBack }: { onBack: () => void }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { preRegProfile } = useSelector((state: RootState) => state.auth);
  const [code, setCode] = React.useState('');

  const onCreatePartner = () => {
    if (preRegProfile && code.length != 0) {
      dispatch(verifyEmail({ email: preRegProfile.email, otp_code: code, partner: preRegProfile }))
    }
  }

  return (
    <div>
      <Typography variant='subtitle1' component={'p'} >
        {t("send-email-text", { email: preRegProfile?.email })}
      </Typography>
      <TextField
        name='title'
        label={t('code')}
        value={code}
        inputProps={{
          onChange: (event: any) => { setCode(event.target.value) }
        }}
        InputLabelProps={{
          shrink: true,
        }}
        fullWidth
        style={{
          marginTop: 12
        }} />
      <Button
        style={{ marginTop: 24 }}
        type='submit'
        variant="contained"
        onClick={onCreatePartner}
        color='primary'
        fullWidth
      >
        {t("submit-registration")}
      </Button>
      <Button
        style={{ marginTop: 12 }}
        variant='outlined'
        onClick={() => { onBack() }}
        fullWidth>
        {t("back")}
      </Button>
    </div>
  )
}