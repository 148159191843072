import { Button, SvgIcon, Typography, makeStyles } from "@material-ui/core"
import { Link, useNavigate } from 'react-router-dom'
import { LangSwitch } from "../components/LangSwitch/LandSwitch";
import { useTranslation } from "react-i18next";

import { ReactComponent as LogoDashborad } from '../icon/headLogo.svg';
import { ReactComponent as PlusIcon } from '../icon/plus.svg'
import { ReactComponent as TourIcon } from '../icon/IconTour.svg'

import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../store";
import { logOut } from "../store/auth/authStore";


const useStyle = makeStyles({
  navBox: {
    width: '219px',
    minWidth: '219px',
    height: '100%',
    background: '#071226',
    borderRight: '1px solid rgba(65, 145, 247, 0.08)',
    position: 'fixed',
    zIndex: 2,
    top: 0,
    left: 0,

    display: 'flex',
    flexDirection: 'column',
    '& nav': {
      marginTop: '24px',
    }
  },
  langBox: {
    marginTop: 'auto',
    display: 'flex',
    padding: '19px 13px',
    '& .MuiButtonBase-root': {
      marginLeft: 'auto'
    }
  },
  linkLogo: {
    display: 'block',
    height: '44px',
    width: '153px',
    marginLeft: 'auto',
    marginRight: '13px',
    marginTop: '12px',
    '& svg': {
      width: '100%',
      height: '100%',
    }
  },
  linkItem: {
    display: 'flex',
    alignItems: 'center',
    width: '211px',
    height: '40px',
    margin: '4px auto',
    borderRadius: '8px',
    '& span,& svg': {
      marginLeft: 16,
    },
    '& svg path': {
      fill: 'rgba(255,255,255,0)',
    },
    '&:hover': {
      background: 'rgba(65, 145, 247, 0.05)',
    }

  },
  linkItemActive: {
    background: 'rgba(65, 145, 247, 0.12)',
    '&:hover': {
      background: 'rgba(65, 145, 247, 0.12) !important',
    }
  },
  createLink: {
    marginTop: '15px',
    border: ' 1px solid #4191F7',
  }
})

interface NavLinkProps {
  href: string,
  title: string,
  icon: React.FunctionComponent
}

const isPathnameActive = (locationPathname: string, path: string) => {
  if (path === '/') {
    return path === locationPathname || locationPathname.startsWith('/profile');
  }

  return locationPathname.startsWith(path);
}

const NavLink = (props: NavLinkProps) => {
  const cx = useStyle();
  const location = useLocation()
  const isActive = isPathnameActive(location.pathname, props.href);

  return (
    <li>
      <Link className={clsx(cx.linkItem, isActive && cx.linkItemActive)} to={props.href}>
        <SvgIcon component={props.icon} viewBox="0 0 16 16" />
        <Typography component={'span'}>
          {props.title}
        </Typography>
      </Link>
    </li>
  )
}


export const AdminNavBar = () => {
  const cx = useStyle();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const NavLinkList: NavLinkProps[] = [
    {
      href: "/",
      title: t("my-tournaments"),
      icon: TourIcon
    }
  ]

  return (
    <header className={cx.navBox}>
      <div>
        <Link className={cx.linkLogo} to="/">
          <SvgIcon component={LogoDashborad} viewBox="0 0 153 44" />
        </Link>
      </div>
      <nav>
        <ul>
          {
            NavLinkList.map(
              (item, index) =>
                <NavLink
                  key={index}
                  href={item.href}
                  title={item.title}
                  icon={item.icon}
                />
            )
          }
          <li>
            <Link className={clsx(cx.linkItem, cx.createLink)} to={"/tournament/create"}>
              <SvgIcon component={PlusIcon} viewBox="0 0 16 16" />
              <Typography component={'span'}>
                {t('create-tournament')}
              </Typography>
            </Link>
          </li>
        </ul>
      </nav>

      <div className={cx.langBox}>
        <LangSwitch />
      </div>
      <Button
        onClick={() => {
          dispatch(logOut());
          navigate(0);
        }}
      >
        <Typography variant="body1" color="secondary">
          {t("logout")}
        </Typography>
      </Button>
    </header>
  )
}