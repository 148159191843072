import { Button, FormControl, FormLabel, Tab, Tabs, Typography, makeStyles } from '@material-ui/core';
import { TextField, makeValidate } from 'mui-rff';
import React from 'react'
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Schema } from 'yup';
import * as Yup from 'yup'
import { PersonelInfoProps, TypeOrganization } from './RegisterForm';
import { RootState, useAppDispatch, useAppSelector } from '../../store';
import { setPreRegProfile } from '../../store/auth/authStore';
import { sendEmailCode } from '../../store/auth/thunks';

interface OrganizationStepProps {
  typeOrg: TypeOrganization,
  personel: PersonelInfoProps,
  sypexGeo: any,
  onBack: () => void,
  onSendCode: () => void,
}

const useStyle = makeStyles({
  flexForm: {
    display: 'flex',
    gap: '27px',
    '& .MuiTextField-root': {
    }
  },
  flexColumn: {
    maxWidth: 256
  },
  tabsBox: {
    '& .MuiTab-root': {
      width: '50%',
      minWidth: '100px',
      marginTop: '3px',
    }
  }
})

export const OrganizationStep = ({ onBack, personel, typeOrg, onSendCode, sypexGeo }: OrganizationStepProps) => {
  const { t } = useTranslation();
  const classes = useStyle()
  const dispatch = useAppDispatch()
  const { preRegProfile } = useAppSelector((state: RootState) => state.auth)

  const [initialValues, setInitialValues] = React.useState({
    country: "",
    city: "",
    address: "",
    "reg-number": "",
    firstname: "",
    lastname: "",
    scope: "",
    openGame: 'yes'
  })

  const schema: Schema = Yup.object().shape({
    country: Yup.string().required(`${t('required')}`),
    city: Yup.string().required(`${t('required')}`),
    address: Yup.string().required(`${t('required')}`),
    "reg-number": Yup.string().required(`${t('required')}`),
    firstname: Yup.string().required(`${t('required')}`),
    lastname: Yup.string().required(`${t('required')}`),
    scope: Yup.string()
  });

  const validate = makeValidate(schema);
  const onSubmit = (obj: any) => {
    const body = {
      partner_name: personel.title,
      email: personel.email,
      phone: personel.phone,
      type: typeOrg,
      country: obj.country,
      city: obj.city,
      address: obj.address,
      registration_number: obj["reg-number"],
      first_name: obj.firstname,
      last_name: obj.lastname,
      scope_of_business: obj.scope,
      closed_tournaments_only: obj.openGame,
      location: {
        latitude: sypexGeo ? sypexGeo.city.lat : '0',
        longitude: sypexGeo ? sypexGeo.city.lon : '0'
      }
    }

    dispatch(setPreRegProfile(body))
    dispatch(sendEmailCode(personel.email))
    onSendCode()
  }

  const onChange = (event: any) => {
    setInitialValues({ ...initialValues, [event.target.name]: event.target.value })
  }
  const onChangeTabs = (event: React.ChangeEvent<{}>, newValue: 'yes' | 'no') => {
    setInitialValues({ ...initialValues, openGame: newValue })
  }

  React.useEffect(() => {
    if (sypexGeo) {
      setInitialValues({
        ...initialValues,
        country: sypexGeo.country.name_en,
        city: sypexGeo.city.name_en,
      })
    }
  }, [])

  React.useEffect(() => {
    if (preRegProfile) {
      setInitialValues({
        country: preRegProfile.country,
        city: preRegProfile.city,
        address: preRegProfile.address,
        "reg-number": preRegProfile.registration_number,
        firstname: preRegProfile.first_name,
        lastname: preRegProfile.last_name,
        scope: preRegProfile.scope_of_business,
        openGame: preRegProfile.closed_tournaments_only ? 'yes' : 'no'
      });
    }
  }, [])

  return (
    <>
      <Typography style={{ marginTop: 28 }}>
        {
          typeOrg === 'club' ?
            t("club-user_name", { name: personel.title })
            :
            t("organization-user_name", { name: personel.title })
        }
      </Typography>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validate}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div className={classes.flexForm}>
                <div className={classes.flexColumn}>
                  <TextField
                    name='country'
                    label={t('country')}
                    inputProps={{
                      onChange
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{
                      marginTop: 12
                    }} />

                  <TextField
                    name='city'
                    type='text'
                    label={t('city')}
                    inputProps={{
                      onChange
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{
                      marginTop: 12
                    }} />

                  <TextField
                    name='address'
                    type='text'
                    label={t('address')}
                    inputProps={{
                      onChange
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{
                      marginTop: 12
                    }} />
                  {
                    typeOrg === 'organization' &&
                    <div style={{
                      marginTop: 12
                    }}>
                      <Typography variant='subtitle2' component={'span'}>
                        {t('opened-tournaments')}
                      </Typography>
                      <Tabs
                        value={values.openGame}
                        className={classes.tabsBox}
                        onChange={onChangeTabs}>
                        <Tab label={t("Yes")} value={'yes'} />
                        <Tab label={t("No")} value={'no'} />
                      </Tabs>
                    </div>
                  }
                  <Button
                    style={{ marginTop: 24 }}
                    variant='outlined'
                    onClick={onBack}
                    fullWidth>
                    {t("back")}
                  </Button>
                </div>
                <div className={classes.flexColumn}>
                  <TextField
                    name='reg-number'
                    label={t('reg-number')}
                    inputProps={{
                      onChange
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{
                      marginTop: 12
                    }} />

                  <TextField
                    name='firstname'
                    type='text'
                    label={t('firstname')}
                    inputProps={{
                      onChange
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{
                      marginTop: 12
                    }} />

                  <TextField
                    name='lastname'
                    type='text'
                    label={t('lastname')}
                    inputProps={{
                      onChange
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    style={{
                      marginTop: 12
                    }} />
                  {
                    typeOrg === 'organization' &&
                    <TextField
                      name='scope'
                      label={t('scope')}
                      inputProps={{
                        onChange
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      style={{
                        marginTop: 12
                      }} />
                  }
                  <Button
                    type='submit'
                    variant="contained"
                    color='primary'
                    fullWidth
                    style={{ marginTop: typeOrg === 'club' ? '24px' : '48px' }}
                  >
                    {t("confirm-email")}
                  </Button>
                </div>
              </div>
            </form >);
        }}
      />
    </>
  )
}