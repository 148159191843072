import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getManualMatches, getTournamentById, getTournaments, removeTeamFormTournament, setManualMatches } from "./thunks";
import { Placement, Team, Tournament } from "../../entities/Tournaments";
import { RootState } from "..";
import { format } from "date-fns";

interface TournamentState {
  tournaments: Tournament[],
  editTournament?: Tournament,
  loading: boolean,
  saved: boolean,
  placements: Placement[],
  teamList: Team[],
  errors: {
    DateTime?: { round: number, message: string }[],
    saved?: string
  }
}


const initialState: TournamentState = {
  tournaments: [],
  editTournament: undefined,
  loading: false,
  saved: false,
  placements: [],
  teamList: [],
  errors: {}
};

export const tournamentSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateTimeStartByRound: (state, action: PayloadAction<{ round: number, date: string }>) => {
      if (state.placements.length > 0) {
        const date = new Date(action.payload.date);
        const arr = state.placements
          .map((item) => {
            if (item.round === action.payload.round)
              return ({
                ...item,
                start_datetime: `${format(date, "yyyy-MM-dd")}T${format(date, "HH:mm:00")}`
              })
            return { ...item }
          })
        state.placements = (arr);
      }
    },
    // errorTimeStartByRound: (state, action: PayloadAction<{ round: number }>) => {
    //   state.errors = { DateTime: [...state.errors.DateTime, { round: action.payload.round, message: 'Не валидная дата' }] }
    // },
    clearErrors: (state) => {
      state.errors = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTournaments.pending, state => {
      state.loading = true
    })
    builder.addCase(getTournaments.fulfilled, (state, action) => {
      state.loading = false
      state.tournaments = action.payload || []
    })

    builder.addCase(getTournamentById.pending, state => {
      state.loading = true
    })
    builder.addCase(getTournamentById.fulfilled, (state, action) => {
      state.loading = false
      state.editTournament = action.payload
      if (action.payload && action.payload.teams.length > 0) {
        state.teamList = action.payload.teams.map((item) => item.team);
      }
    })
    builder.addCase(getManualMatches.fulfilled, (state, action) => {
      state.placements = action.payload;
    })
    builder.addCase(setManualMatches.pending, (state) => {
      // state.loading = true;
      state.saved = false;
    })
    builder.addCase(setManualMatches.fulfilled, (state, action) => {
      state.placements = action.payload;
      // state.loading = false;
      state.saved = true;
    })
    builder.addCase(setManualMatches.rejected, (state, action) => {
      // state.loading = false;
      state.saved = false;
      state.errors.saved = action.error.message ? action.error.message : "Error saved manual select"
    })

    builder.addCase(removeTeamFormTournament.pending, state => {
      state.loading = true
    })
    builder.addCase(removeTeamFormTournament.fulfilled, (state, action) => {
      state.loading = false
      state.editTournament = action.payload
    })
  }
});

export const { updateTimeStartByRound, clearErrors } = tournamentSlice.actions;

export const selectStatusTournament = (state: RootState) => state.tournament.editTournament ? state.tournament.editTournament.status_value : null;

export default tournamentSlice.reducer;