import { Box, makeStyles } from "@material-ui/core"
import { Alert, AlertTitle } from '@material-ui/lab'
import React from "react"
import { RootState, useAppDispatch, useAppSelector } from "../store"
import { deleteNotification } from "../store/notification/notificationStore"

const notificationStyle = makeStyles({
  root: {
    position: 'fixed',
    left: '50%',
    top: 90,
  }
})


export const Notification = () => {
  const classes = notificationStyle()
  const { notification } = useAppSelector(
    (state: RootState) => state.notifications
  );
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (notification.length > 0) {
      setTimeout(() => {
        dispatch(deleteNotification());
      }, 3000);
    }
  }, [notification]);

  return (
    <Box className={classes.root}>
      {
        notification.map(item => <Alert severity={item.severity}>
          {/* <AlertTitle>Error</AlertTitle> */}
          {item.message}
        </Alert>)
      }
    </Box>
  )
}