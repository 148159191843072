import React from 'react'
import { useTranslation } from "react-i18next";
import { Lang, languageLocalStorageKey } from "../../config/config";
import { Button, makeStyles } from '@material-ui/core'
import { ReactComponent as Global } from '../../icon/globe-alt.svg'

const useStyle = makeStyles({
  btnText: {
    '& .MuiButton-label': {
      textTransform: 'uppercase',
      fontSize: '12px',
      lineHeight: '20px',

      background: ' linear-gradient(180deg, #4191F7 0%, #0F78FF 100%)',
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent',
      backgroundClip: 'text',
      textFillColor: ' transparent',
    }
  }
})

export const LangSwitch = () => {
  const classes = useStyle();
  const { i18n } = useTranslation();
  const [selected, selectedSet] = React.useState<Lang>(i18n.language as Lang);

  const onLangSwitch = React.useCallback((lang: Lang) => {
    selectedSet(lang);
    i18n.changeLanguage(lang);
    localStorage.setItem(languageLocalStorageKey, lang);
  }, [i18n])

  return (
    <Button
      color="secondary"
      className={classes.btnText}
      endIcon={<Global />}
      onClick={() => { onLangSwitch(selected === 'ru' ? 'en' : 'ru') }}
    >
      {(selected === 'ru' || selected === 'ru-RU') ? 'ru' : 'en'}
    </Button>
  )
}