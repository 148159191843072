import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AdminNavBar } from "./AdminNavBar";
import { MainContainer } from "./Page";
import { Notification } from "./Notification";

// const DashboardPage = React.lazy(async () => (await import('../Pages/Dashboard/DashboardPage')));
const TournamentsPage = React.lazy(async () => (await import('../Pages/Tournaments/TournamentsPage')));
// const SingleTournament = React.lazy(async () => (await import('../Pages/Tournaments/TournamentSinglePage')));
const CreateTour = React.lazy(async () => (await import('../Pages/Tournaments/Create/CreateTournament')))
const TournamentEditPage = React.lazy(async () => (await import('../Pages/Tournaments/Edit/TournamentEditPage')))

const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <AdminNavBar />
      <MainContainer>
        {children}
      </MainContainer>
      <Notification />
    </>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Container>
      <TournamentsPage />
      {/* <DashboardPage /> */}
    </Container>,
  },
  {
    path: "tournaments",
    element: <Container>
      <TournamentsPage />
    </Container>,
  },
  {
    path: "tournament/edit/:id",
    element: <Container>
      <TournamentEditPage />
    </Container>
  },
  {
    path: "tournament/create",
    element: <Container>
      <CreateTour />
    </Container>
  }
])


const AdminRouter = () => {
  return (
    <RouterProvider router={router} />
  )
}

export default AdminRouter; 