import React from 'react'
import { Link, SvgIcon } from '@material-ui/core'
import { ReactComponent as Insta } from '../../icon/social/bxl-instagram-alt.svg'
import { ReactComponent as Youtube } from '../../icon/social/YouTube.svg'
import { ReactComponent as Discord } from '../../icon/social/Discord.svg'
import { ReactComponent as Twitch } from '../../icon/social/bxl-twitch.svg'
import { ReactComponent as Telegram } from '../../icon/social/bxl-telegram.svg'
import { ReactComponent as Vk } from '../../icon/social/bxl-vk.svg'
import { ReactComponent as Twitter } from '../../icon/social/bxl-twitter.svg'


interface SocialProps {
  title: string,
  link: string,
  icon: React.FunctionComponent
}

export const Social: SocialProps[] = [
  {
    title: "Instagram",
    link: "https://www.instagram.com/wisp.game/",
    icon: Insta
  },
  {
    title: "Youtube",
    link: "https://www.youtube.com/@wisp_game",
    icon: Youtube
  },
  {
    title: "Discord",
    link: "https://discord.gg/bdRW3p674Z",
    icon: Discord
  },
  {
    title: "Twitch",
    link: "https://www.twitch.tv/wisp_eplatform",
    icon: Twitch
  },
  {
    title: "Telegram",
    link: "https://t.me/wispplatform",
    icon: Telegram
  }, {
    title: "Vk",
    link: "https://vk.com/wisp.game",
    icon: Vk
  }, {
    title: "Twitter",
    link: "https://twitter.com/game_wisp",
    icon: Twitter
  },
]



export const SocialItem = (props: SocialProps) => {
  return (
    <Link href={props.link} target='_blank' title={props.title}>
      <SvgIcon component={props.icon} viewBox="0 0 24 24" />
    </Link>
  )
}

export const SocialList = () => {
  return (
    <ul>
      {
        Social.map((item, index) => <li key={`social_${index}`}><SocialItem {...item} /></li>)
      }
    </ul>
  )
}