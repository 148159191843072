import { makeStyles } from '@material-ui/core'
import { PaymentList } from '../../components/PaymentList/PaymentList';
import { SocialList } from '../../components/SocialList/SocialList';


const useStyles = makeStyles({
  footer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '63px',
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 54px',
    height: '63px',
  },
  paymentMethod: {
    '& ul': {
      display: 'flex',
      '& li': {
        height: '36px',
      }
    }
  },
  socialListBox: {
    '& ul': {
      display: 'flex',
      '& li a': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '40px',
        height: '40px',
      }
    }
  }
})

export const FooterLogin = () => {
  const classes = useStyles();


  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.paymentMethod}>
          <PaymentList />
        </div>
        <div className={classes.socialListBox}>
          <SocialList />
        </div>
      </div>
    </footer>
  )
}