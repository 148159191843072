import React from 'react'

import * as Yup from "yup";
import { Typography, makeStyles, Button, InputAdornment, IconButton, Checkbox, FormControlLabel } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { TextField, makeValidate } from 'mui-rff'
import { Form } from 'react-final-form'
import { useTranslation } from 'react-i18next'

import { ReactComponent as CheckboxIcon } from '../../icon/Checkbox.svg'
import { ReactComponent as CheckboxCheckedIcon } from '../../icon/Checkbox-1.svg'
import { Schema } from 'yup';
import { useAppDispatch } from '../../store';
import { loginParther } from '../../store/auth/thunks';


const useStyle = makeStyles({
  createrBox: {
    textAlign: 'center',
    marginTop: 24,
    '& p': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      textAlign: 'center',
      color: 'rgba(255, 255, 255, 0.56)',
      paddingBottom: 3,
      marginBottom: 16,
      '&::after': {
        content: '" "',
        display: 'block',
        height: '4px',
        width: "100%",
        borderBottom: '1px solid rgba(65, 145, 247, 0.08)',
        position: 'absolute',
        left: 0,
        right: 0,
      }
    }
  }
})

const ContentForm = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch()

  const [initialValues, setInitialValues] = React.useState({
    email: localStorage.userEmail || "",
    password: "",
    showPassword: false,
    save: !!localStorage.userEmail,
  })

  const schema: Schema = Yup.object().shape({
    email: Yup.string().email(`${t("login-email-error")}`).required(`${t('required')}`),
    password: Yup.string().required(`${t('required')}`),
  });

  const validate = makeValidate(schema);

  const onChange = (event: any) => {
    setInitialValues({ ...initialValues, [event.target.name]: event.target.value })
  }
  const onChangeCheckbox = (event: any) => {
    setInitialValues({ ...initialValues, save: event.target.checked })

  }

  const handleClickShowPassword = () => {
    setInitialValues({ ...initialValues, showPassword: !initialValues.showPassword });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = (obj: any) => {
    if (initialValues.save) {
      localStorage.userEmail = obj.email
    }
    dispatch(loginParther(obj));
  }

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <div >
              <TextField
                name='email'
                type='email'
                label={t('login-email')}
                inputProps={{
                  onChange
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                style={{
                  marginTop: 24
                }} />
            </div>
            <div >
              <TextField
                name='password'
                label={t('password')}
                type={values.showPassword ? 'text' : 'password'}
                inputProps={{
                  onChange
                }}

                InputProps={{
                  endAdornment:
                    < InputAdornment position="end" >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                fullWidth
                style={{
                  marginTop: 12
                }} />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.save}
                  onChange={onChangeCheckbox}
                  icon={<CheckboxCheckedIcon />}
                  checkedIcon={<CheckboxIcon />}
                  name="save" />
              }
              label={t("remember-me")}
            />
            <div style={{
              marginTop: 24
            }}>
              <Button
                type='submit'
                variant="contained"
                color='primary'
                fullWidth
              >
                {t("login-button")}
              </Button>
            </div>
          </form >
        );
      }}
    />
  )
}

export const LoginForm = ({ onRegister }: { onRegister: () => void }) => {
  const { t } = useTranslation();
  const classes = useStyle();
  return (
    <div>
      <Typography
        variant='h6'
        component={'h2'}
        style={{ textAlign: 'center' }}
      >
        {t('login')}
      </Typography>
      <div>
        <ContentForm />
      </div>
      <div className={classes.createrBox}>
        <p>
          {t("login-or")}
        </p>
        <Button
          variant='outlined'
          onClick={onRegister}
          fullWidth>
          {t("create-partner-account")}
        </Button>
      </div>
    </div>
  )
}