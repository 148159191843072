import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useGlobalStyles, wispTheme } from './Theme/WISPTheme';
import { Provider } from 'react-redux';
import store, { RootState, useAppDispatch, useAppSelector } from './store';
import { ProfileContext } from './store/auth/userProfileContext';
import AdminRouter from './navigation/AdminRouter';
import { AuthPage } from './Pages/Auth/AuthPage';
import { loadUser } from './store/auth/thunks';

const RouteOnAuth = () => {
  const { token, profile, loading } = useAppSelector((state: RootState) => state.auth)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (token && !profile) {
      dispatch(loadUser(token))
    }
  }, [dispatch, profile, token])

  if (!profile && loading) {
    return <div />;
  }

  return (
    <>
      {
        !!profile && (
          <React.Suspense fallback={<div />}>
            <ProfileContext.Provider value={profile}>
              <AdminRouter />
            </ProfileContext.Provider>
          </React.Suspense>)
      }
      {
        !profile && (
          <React.Suspense fallback={<div />}>
            <AuthPage />
          </React.Suspense>)
      }
    </>
  )
}


function AdminApp() {
  useGlobalStyles();
  return (
    <ThemeProvider theme={wispTheme}>
      <CssBaseline />
      <Provider store={store}>
        <RouteOnAuth />
      </Provider>
    </ThemeProvider>
  );
}

export default AdminApp;
