import 'react-phone-number-input/style.css'
import PhoneInput, { Value, isValidPhoneNumber } from 'react-phone-number-input'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'

interface WISPPhoneInputProps {
  label: string,
  isError: boolean
  inputPhone: {
    value: Value
    onChangePhone: (phone: Value) => void
  }
}

const useStyle = makeStyles({
  phoneBox: {
    marginTop: 12,
    '& .PhoneInput': {
      color: '#fff',
      border: '0.5px solid rgba(65, 145, 247, 0.52)',
      padding: '8px 0px 8px 12px',
      fontSize: 14,
      background: 'rgba(255, 255, 255, 0.08)',
      fontFamily: '"Inter", sans-serif',
      fontWeight: 400,
      lineHeight: '16px',
      marginTop: 4,
      borderRadius: 8,
      '& .PhoneInputInput': {
        color: '#fff',
        border: 'none',
        background: 'none'
      }
    }
  },
  phoneLabel: {
    color: 'rgba(255, 255, 255, 0.32)',
    fontSize: 14,
    fontWeight: 400,
    fontFamily: '"Inter", sans-serif',
    lineHeight: '16px',
    marginBottom: 4,
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
  },
  phoneLabelFocuse: {
    color: 'rgba(255, 255, 255, 1)',
    transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms'
  },
  phoneError: {
    '& label': {
      color: '#D24E4E',
    },
    '& .PhoneInput': {
      borderColor: '#D24E4E',
    }
  },
  phoneErrorText: {
    color: '#D24E4E',
    fontSize: '0.75rem',
    marginTop: 3,
    textAlign: 'left',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '1.66',
    letterSpacing: '0.03333em'
  }
})

export const WISPPhoneInput = ({ inputPhone, label, isError }: WISPPhoneInputProps) => {
  const { t } = useTranslation()
  const { value, onChangePhone } = inputPhone
  const [isFocus, setIsFocus] = React.useState<boolean>(false);
  const [errors, setErrors] = React.useState<boolean>(isError)
  const classes = useStyle({ focus: isFocus })

  const onChange = (event: Value) => {
    if (event && !isValidPhoneNumber(event)) {
      setErrors(true);
    } else {
      setErrors(false);
    }
    onChangePhone(event);
  }

  return (
    <div className={clsx(classes.phoneBox, errors && classes.phoneError)}>
      <label className={clsx(
        classes.phoneLabel,
        isFocus && classes.phoneLabelFocuse
      )} htmlFor='phone'>
        {label}
      </label>

      <PhoneInput
        international
        defaultCountry="BY"
        value={value}
        onFocus={() => { setIsFocus(true) }}
        onBlur={() => { setIsFocus(false) }}
        id='phone'
        name="phone"
        onChange={onChange}
      />
      {
        errors
        && <span className={classes.phoneErrorText}>{t('login-phone-error')}</span>
      }
    </div>
  )
}