import React from "react";
import { useTranslation } from "react-i18next";
import { Lang, languageLocalStorageKey } from "../../config/config";
import { makeStyles, Link, SvgIcon } from "@material-ui/core";
import { ReactComponent as LogoDashborad } from '../../icon/logoDashboard.svg'
import { LangSwitch } from "../../components/LangSwitch/LandSwitch";

const RUS = ['ru', 'ru-RU']
const ENG = ['en', 'en-EN']

const useStyles = makeStyles({
  header: {
    width: '100%',
    borderBottom: '1.43px solid rgba(65,145,247,.08)',
    display: 'flex',
    alignItems: 'center',
    height: '63px',
  },
  headerBox: {
    width: '100%',
    margin: '0px 53px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkLogo: {
    display: 'block',
    height: '47px',
    width: '263px',
    '& svg': {
      width: '100%',
      height: '100%',
    }
  }
})

export const HeaderLogin = () => {
  const classes = useStyles();


  return (
    <header className={classes.header}>
      <div className={classes.headerBox}>
        <Link className={classes.linkLogo} href="/">
          <SvgIcon component={LogoDashborad} viewBox="0 0 263 47" />
        </Link>
        <LangSwitch />
      </div>
    </header>
  )
}