import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { PreRegPartner } from "../../entities/UserProfile";

export const registretionPartner = createAsyncThunk(
  'auth/registretion',
  async (data: PreRegPartner) => {
    const res = agent.post(`${apiUrlV1}/admin/partner/register/`, {
      body: JSON.stringify(data)
    })
  }
);

export const sendEmailCode = createAsyncThunk(
  'email/send-code',
  async (email: string) => {
    const res = agent.post(`${apiUrlV1}/send-code/`, {
      body: JSON.stringify({ email, type: "email_verify" })
    })
  }
);
interface VerifyEmailProps {
  email: string,
  otp_code: string,
  partner: PreRegPartner
}

export const verifyEmail = createAsyncThunk(
  'email/verify',
  async (props: VerifyEmailProps, thunkAPI) => {
    const res = agent.post(`${apiUrlV1}/email/verify/`, {
      body: JSON.stringify({ email: props.email, otp_code: props.otp_code })
    })
    if ((await res).status === 200) {
      thunkAPI.dispatch(registretionPartner(props.partner))
    }
  }
)

interface LoginProps {
  email: string,
  password: string
}
interface Tokens {
  email: string,
  tokens: {
    refresh: string,
    access: string
  }
}

export const loadUser = createAsyncThunk(
  'auth/userLogin',
  async (token: string) => {
    const res = await fetch(`${apiUrlV1}/admin/me/`, {
      headers: {
        'Authorization': `Token ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (res.status === 200) {
      return await res.json();
    }
    if (res.status === 401) {
      localStorage.removeItem('token')
    }
  }
)

export const loginParther = createAsyncThunk(
  'auth/login',
  async (props: LoginProps, thunkAPI) => {
    const res = await agent.post(`${apiUrlV1}/admin/partner/token/`, {
      body: JSON.stringify(props)
    })

    if (res.status === 200) {
      const body: Tokens = await res.json()
      thunkAPI.dispatch(loadUser(body.tokens.access))
      return body as Tokens
    }
  }
)

