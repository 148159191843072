import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { AddTournament, MatchPlacement, Placement, Tournament } from "../../entities/Tournaments";

export const addTournament = createAsyncThunk(
  'tournament/add',
  async (body: AddTournament) => {
    const res = await agent.post(`${apiUrlV1}/admin/tournaments/`, {
      headers: {
        'Authorization': `Token ${localStorage.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    })

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()
      return body
    }
  }
);

export const getTournaments = createAsyncThunk(
  'tournament/get-list',
  async () => {
    const res = await agent.get(`${apiUrlV1}/admin/tournaments/`)

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()
      return body.results as Tournament[]
    }
  }
);

export const getTournamentById = createAsyncThunk(
  'tournament/edit',
  async (id: string) => {
    const res = await agent.get(`${apiUrlV1}/admin/tournaments/${id}`)

    if (res.status === 200 || res.status === 201) {
      const body = await res.json()
      return body as Tournament
    }
  }
);

export const updateTournamentById = createAsyncThunk(
  'tournament/update',
  async (body: Tournament) => {
    const res = await agent.put(`${apiUrlV1}/admin/tournaments/${body.id}`, {
      body: JSON.stringify(body)
    })

    if (res.status === 200) {
      const body = await res.json()
      return body as Tournament
    }
  }
)

export const deleteTournamentById = createAsyncThunk(
  'tournament/delete',
  async (id: number) => {
    const res = await agent.delete(`${apiUrlV1}/admin/tournaments/${id}`)
  }
)

export const getManualMatches = createAsyncThunk(
  'matches/get-manual',
  async (id: string) => {
    const req = await agent.get(`${apiUrlV1}/steam/tournament-matches/?tournament_id=${id}`)

    if (req.status === 200) {
      const res = await req.json();

      return res as Placement[];
    }
    return [];
  }
)

export const setManualMatches = createAsyncThunk(
  'matches/set-manual',
  async (props: { id: number, body: MatchPlacement[] }) => {
    const req = await agent.put(`${apiUrlV1}/admin/tournaments/${props.id}/placements`, {
      body: JSON.stringify(props.body),
    })

    if (req.status === 200) {
      const res = await req.json();

      return res as Placement[];
    } else if (req.status === 400) {
      const res = await req.json();
      if (res[0].start_datetime) {
        throw Error(res[0].start_datetime);
      }

      throw Error('Error saved manual');
    }

    throw Error('Error saved manual');
  }
)

export const removeTeamFormTournament = createAsyncThunk(
  'team/remove',
  async (props: { tournamentId: number, teamId: number }) => {
    const { teamId, tournamentId } = props;
    const req = await agent.delete(`${apiUrlV1}/admin/tournaments/kick-team/${tournamentId}/?team_id=${teamId}`)

    if (req.status === 200 || req.status === 201) {
      const body = await req.json()
      return body as Tournament
    }
  }
)