import React from 'react'
import { FooterLogin } from "./FooterLogin"
import { HeaderLogin } from "./HeaderLogin"
import { makeStyles, withStyles, Box } from '@material-ui/core'

import bgEffect from '../../image/effects.png'
import { LoginForm } from "./LoginForm"
import { RegisterForm } from './RegisterForm'

const useAuthGlobalStyle = makeStyles({
  "@global": {
    '#root': {
      '&::after': {
        content: "' '",
        position: 'fixed',
        zIndex: 0,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: `url(${bgEffect})`,
        opacity: 0.16,
      }
    },
    'footer, header, main': {
      position: 'relative',
      zIndex: 2,
    }
  }
})

const LayoutAuth = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <HeaderLogin />
      <main>
        {children}
      </main>
      <FooterLogin />
    </>
  )
}

const SectionForm = withStyles({
  root: {
    background: '#111C33',
    borderRadius: '12px',
    padding: '24px 56px',
    width: 'fit-content',
    margin: '64px auto 0px auto',
    minWidth: '368px',
    position: "relative",

    boxShadow: '0px 11px 15px -7px rgba(0, 0, 0, 0.20), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
  }
})(Box)

const AuthForm = () => {
  const [method, setMethod] = React.useState<'login' | 'create'>('login')

  const onSwitchMethed = React.useCallback(() => {
    setMethod(method === 'login' ? 'create' : 'login')
  }, [method])

  return (
    <SectionForm component={'section'}>
      {
        method === 'login' &&
        <LoginForm onRegister={onSwitchMethed} />
      }
      {
        method === 'create' &&
        <RegisterForm onBack={onSwitchMethed} />
      }
    </SectionForm >
  )
}

export const AuthPage = () => {
  useAuthGlobalStyle();
  return (
    <LayoutAuth>
      <AuthForm />
    </LayoutAuth>
  )
}