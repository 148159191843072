import React from 'react'
import visa from "../../icon/visa-transparent-gray 1.png"
import mastercard from "../../icon/mc-transparent-gray 1.png"
import iPay from "../../icon/apple-pay-gray 1.png"
import samsungPay from "../../icon/samsung-pay-grey@2x 1.png"
import gPay from "../../icon/gpay-gray 2.png"
import yPay from "../../icon/Frame 36500.png"
import mir from "../../icon/mir-transparent-gray 1.png"
import belcard from "../../icon/belkart-internet-password-transparent-gray 1.png"
import bePaid from "../../icon/bePaid-transparent-gray 1.png"


export const Payment: PaymentImageProps[] = [
  {
    imageUrl: visa,
    title: "Visa"
  },
  {
    imageUrl: mastercard,
    title: "mastercard"
  },
  {
    imageUrl: iPay,
    title: "Apply Pay"
  },
  {
    imageUrl: samsungPay,
    title: "Samsung Pay"
  },
  {
    imageUrl: gPay,
    title: "Google Pay"
  },
  {
    imageUrl: yPay,
    title: "Yandex Pay"
  },
  {
    imageUrl: mir,
    title: "MIR"
  },
  {
    imageUrl: belcard,
    title: "Belcard"
  },
  {
    imageUrl: bePaid,
    title: "bePayd"
  },
]

interface PaymentImageProps {
  title: string,
  imageUrl: string,
}

export const PaymentItem = (props: PaymentImageProps) => {
  return (
    <img src={props.imageUrl} alt={props.title} title={props.title} />
  )
}

export const PaymentList = () => {
  return (
    <ul>
      {
        Payment.map((item, index) => <li key={`payment_${index}`}><PaymentItem {...item} /></li>)
      }
    </ul>
  )
}